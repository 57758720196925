import { create, destroyComponents } from 'zoid';
// zoid patches the window.open function. keeping the native window.open
// @ts-ignore
window.originalOpen = window.open;

// @ts-ignore
window.blnceCheckout = {
  destroy: function () {
    destroyComponents();
  },
  create: create({
    tag: 'blnce-checkout',

    url: ({ props }: any) => {
      // bringing back the original window.open zoid had patched
      // @ts-ignore
      window.open = window.originalOpen;

      if (!props.url) {
        throw new Error('url param is missing');
      }

      return props.url;
    },

    dimensions: {
      height: '100%',
      width: '100%',
    },

    autoResize: {
      height: false,
    },

    prerenderTemplate: function containerTemplate({ doc, props }: any) {
      // ? IMPORTANT - CODE MUST BE ES5!!!
      let color = '#52a3db';

      const html = doc.createElement('html');
      html.innerHTML = `
    <html>
    <head>
      <style>
        .spinner {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .spinner:after {
          content: "";
          position: relative;
          margin: 0 auto;
          display: block;
          width: 40px;
          height: 40px;
          border: 3px solid rgba(255, 255, 255, 0.3);
          border-radius: 50%;
          border-top-color: ${color};
          animation: spin 1s ease-in-out infinite;
          -webkit-animation: spin 1s ease-in-out infinite;
        }

        @keyframes spin {
          to {
            transform: rotate(360deg);
          }
        }

        @-webkit-keyframes spin {
          to {
            -webkit-transform: rotate(360deg);
          }
        }
      </style>
    </head>

    <body>
      <div class='spinner'></div>
    </body>
    </html>
    `;
      return html;
    },

    props: {
      /** 'checkout' | 'quote' */
      type: {
        type: 'string',
        required: false,
      },
      callback: {
        type: 'function',
      },
      logoURL: {
        type: 'string',
      },
      hideBackOnFirstScreen: {
        type: 'boolean',
        required: false,
      },
      checkoutToken: {
        type: 'string',
        required: false,
      },
      checkoutTokens: {
        type: 'array',
        required: false,
      },
      onComplete: {
        type: 'function',
      },
      onError: {
        type: 'function',
        required: false,
      },
      onSuccess: {
        type: 'function',
        required: false,
      },
      onClose: {
        type: 'function',
      },
      onCancel: {
        type: 'function',
        required: false,
      },
      isAuth: {
        type: 'boolean',
        required: false,
      },
    },
  }),
};
